<template>
  <div class="clearfix">
    <a-upload
      :action="action"
      listType="picture-card"
      :fileList="fileList"
      @preview="handlePreview"
      @change="handleChange"
      :multiple="true"
    >
      <div v-if="fileList.length < 5">
        <a-icon type="plus" />
        <div class="ant-upload-text">Upload</div>
      </div>
    </a-upload>
    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>
<script>
// import { Modal } from 'ant-design-vue'
export default {
  data () {
    return {
      previewVisible: false,
      previewImage: '',
      fileList: [],
      action: this.url
    }
  },
  props: {
    url: {
      type: String,
      default: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
      required: false
    }
  },
  methods: {
    handleCancel () {
      this.previewVisible = false
    },
    handlePreview (file) {
      this.previewImage = file.url || file.thumbUrl
      this.previewVisible = true
    },
    handleChange ({ file, fileList }) {
      const isJPG = file.type === 'image/jpeg'
      const isJPEG = file.type === 'image/jpeg'
      const isGIF = file.type === 'image/gif'
      const isPNG = file.type === 'image/png'
      if (!(isJPG || isJPEG || isGIF || isPNG)) {
        this.$error({
          title: '提示',
          content: '只能上传JPG 、JPEG 、GIF、 PNG格式的图片~'
        })
        return
      }
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.$error({
          title: '提示',
          content: '超过2M限制，不允许上传~'
        })
        return
      }
      if ((isJPG || isJPEG || isGIF || isPNG) && isLt2M) {
        this.fileList = fileList
        this.$emit('list', this.fileList)
      }
    }
  }
}
</script>
<style>
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
